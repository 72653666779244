<script lang="ts">
	import { get_nav_submenu_state, get_mobile_nav_state } from '$lib/components/context'
	import { fly } from 'svelte/transition'
	import { quintIn } from 'svelte/easing'

	let is_sub_menu_open = get_nav_submenu_state()
	let is_mobile_menu_shown = get_mobile_nav_state()
</script>

<div
	class="relative -m-3 rounded-lg p-3 focus:ring focus:ring-orange-500 active:bg-gray-50 sm:hover:bg-gray-50 lg:hidden"
>
	<a
		href="/"
		on:click|preventDefault={() => {
			$is_sub_menu_open = !$is_sub_menu_open
		}}
		class="flex items-center"
		aria-expanded={$is_sub_menu_open}
		class:is_sub_menu_open={'text-gray-50'}
	>
		<slot name="title">
			<div
				class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-50 text-white sm:h-12 sm:w-12"
			>
				🎓
			</div>
			<span class="ml-4 md:ml-0">Ratgeber</span>
			<svg
				class="ml-2 h-5 w-5 group-hover:text-orange-600"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
				aria-hidden="true"
			>
				<path
					fill-rule="evenodd"
					d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>
		</slot>
	</a>
	{#if $is_sub_menu_open && $is_mobile_menu_shown}
		<div
			in:fly|global={{ y: -20, opacity: 0.5, duration: 150 }}
			out:fly|global={{ y: -20, opacity: 0, duration: 150, easing: quintIn }}
			class="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform rounded-lg px-4 sm:px-0"
		>
			<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
				<div class="relative grid gap-8 bg-gray-50 p-8 px-5 md:bg-white">
					<slot name="menu-items">
						<a
							on:click={() => {
								$is_mobile_menu_shown = false
							}}
							href="/ratgeber/test"
							class="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
						>
							<div class="h-6 w-6 shrink-0">👀</div>
							<div class="ml-4">
								<p class="text-base font-medium text-gray-900">Titel der Kategorie</p>
								<p class="mt-1 hidden text-sm text-gray-500 md:inline">
									Beschreibung der Kategorie
								</p>
							</div>
						</a>
					</slot>
				</div>
			</div>
		</div>
	{/if}
</div>
