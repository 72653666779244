<script lang="ts">
	import { get_nav_submenu_state } from '$lib/components/context'
	import { fly } from 'svelte/transition'

	let is_sub_menu_open = get_nav_submenu_state()
</script>

<div class="relative hidden md:block">
	<a
		href="/"
		on:click|preventDefault={() => {
			$is_sub_menu_open = !$is_sub_menu_open
		}}
		class="text-whitegroup
      inline-flex items-center text-base font-medium tracking-tight hover:text-orange-600 md:text-lg
      "
		aria-expanded={$is_sub_menu_open}
		class:is_sub_menu_open={'text-gray-50'}
	>
		<slot name="title">
			<span class="ml-4 md:ml-0">Ratgeber</span>
		</slot>
		<svg
			class="ml-2 h-5 w-5 group-hover:text-orange-600"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			fill="currentColor"
			aria-hidden="true"
		>
			<path
				fill-rule="evenodd"
				d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
				clip-rule="evenodd"
			/>
		</svg>
	</a>
	{#if $is_sub_menu_open}
		<div
			in:fly|global={{ y: -30, opacity: 0.5, duration: 200 }}
			class="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform rounded-lg px-4 sm:px-0"
		>
			<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
				<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
					<div class="relative grid gap-6 bg-gray-50 px-5 py-6 sm:gap-8 sm:p-8 md:bg-white">
						<slot name="menu-items">Menu Items in Slot festlegen</slot>
					</div>
					<slot name="teaser" />
				</div>
			</div>
		</div>
	{/if}
</div>
